import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
const PharmaCard = ({ data, handleCompanyName }) => {
  return (
    <>
      <div className="cardparent">
        <div className="container">
          <div className="row justify-content-center">
            {data?.map((item, index) => {
              return (
                <div
                  className="col-lg-4  col-md-6 mt-3 mb-3 d-flex justify-content-center"
                  key={index}
                >
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="features-product-content">
                        <Link>
                          <div className="main-img">
                            <LazyLoadImage src={item.image} alt="img" />
                          </div>
                          <h6
                            className="title text-center   py-3"
                            style={{ color: "grey" }}
                          >
                            {item.title > 0 ? (
                              <span>{item.title}</span>
                            ) : (
                              <span>{item.company}</span>
                            )}
                          </h6>
                        </Link>
                      

                        <div className="features-product-bottom">
                          <Link
                            to={item.url}
                            type="button"
                            className="st-btn st-style1 st-size-large st-color1"
                          >
                            Details
                          </Link>

                          <button
                            onClick={() => handleCompanyName(item.company)}
                            type="button"
                            className="st-btn st-style1 st-size-large st-color1"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModals"
                          >
                            Enquiry
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default PharmaCard;
