import axios from "axios";
import React, { useState } from "react";
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";

import { setCat } from "../Redux/Action";
import { useDispatch } from "react-redux";
import { getCatData } from "../services/api";

const Search = () => {
  const dispatch = useDispatch();
  const [input, setInput] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [modelSearch, setModelSearch] = useState(false);
  const [min, setMin] = useState("");

  const fetchData = async (value) => {
    try {
      const res = await axios.get(getCatData);
      const catData = res.data;
      const result = catData.filter((cat) => {
        return (
          cat &&
          cat.category &&
          cat.category.toLowerCase().includes(value.toLowerCase())
        );
      });
      setSearchResult(result); // Update the search result state
    } catch (error) {}
  };

  const handleChange = (value) => {
    setInput(value);
    if (value.length >= 1) {
      fetchData(value);
    } else {
      setMin("Please enter at least 3 characters.");
      setSearchResult([]);
    }
    if (value.length >= 1) {
      setModelSearch(true);
    } else {
      setModelSearch(false);
    }
  };
  const handleClick = (item) => {
    dispatch(setCat(item));
    setInput("");
    setModelSearch(false);
  };
  const handleClear = () => {
    setInput("");
    setModelSearch(false);
  };
  return (
    <>
      <form
        className="col-lg-6 d-flex justify-content-center justify-content-lg-end"
        role="search"
      >
        <div className="input-group w-75 me-2 input-function">
          <span className="" id="basic-addon2">
            <AiOutlineSearch size={25} className="mt-2 mx-2" />
          </span>
          <input
            type="text"
            size={60}
            className="form-control py-2 rounded-0 "
            placeholder="Search"
            value={input}
            onChange={(e) => handleChange(e.target.value)}
          />
          {input.length >= 1 && (
            <span onClick={handleClear} style={{ cursor: "pointer" }}>
              <AiOutlineClose size={25} className="mt-2 mx-2 " />
            </span>
          )}
        </div>
      </form>
      {/* Display the search results */}
      {modelSearch && (
        <div className="search-align">
          <div className="text-light fw-bolder">Categories</div>

          {searchResult.length === 0 && (
            <div className="text-center">No results found.</div>
          )}
          {searchResult.map((cat, index) => {
            const categorySlugEncoded = cat?.category_slug
              .replace(/\(/g, "%28")
              .replace(/\)/g, "%29")
              .replace(/\//g, "%252F");
            return (
              <ul key={index}>
                <li>
                  <Link to={`/${categorySlugEncoded}/*`} onClick={handleClear}>
                    {cat.category_slug}
                  </Link>
                </li>
              </ul>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Search;
