import React from "react";
import { aboutImg } from "../Assets/images/Image";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import capsule_1 from "../Assets/images/capsule_1.png";
import capsule_2 from "../Assets/images/capsule_2.png";
import { Link } from "react-router-dom";
import Seo from "../Layouts/Seo";

const About = () => {
  return (
    <section id="about">
      <div className="container">
        <div className="float-area ">
          <LazyLoadImage
            className="about-capsule_an floating-img"
            src={capsule_1}
            alt="floatng "
          />
        </div>
      <Seo
        title="Pharma PCD Franchise | Top PCD Pharma Company - India"
        description="Pharma PCD Franchise is the Top PCD Pharma Company in India.Get 60000+ Products in Pharma PCD Franchise from the list of the best 10 PCD Pharma franchise companies in India."
        type="webapp"
        name="Bioraiz Pharma"
        pageUrl={`https://pharmapcdfranchise.co.in/`}
      />
        <h1 className="text-center mt-5 about-padding ">About Us</h1>
        <div className="row pb-1">
          <div className="col-12 col-md-6 center2">
            <h3
              className="text-left w-100 text-center text-sm-start"
              style={{
                fontWeight: "600",
                color: "cornflowerblue",
                fontSize: "25px",
              }}
            >
              Inspiring Health, Empowering Hope{" "}
            </h3>
            <p className="  text-sm-start text-dark fw-normal">
              Welcome to <a href="https://pharmapcdfranchise.co.in/"> Pharma PCD Franchise </a>, a leading pharmaceutical conglomerate
              dedicated to improving global health through innovation, research,
              and quality healthcare solutions. With over 15+ years of
              experience in the <a href="https://en.wikipedia.org/wiki/Pharmaceutical_industry">pharmaceutical industry </a>, we have established
              ourselves as a trusted partner in healthcare in India. Our
              commitment to innovation, quality, and patient-centricity remains
              unwavering. We adhere to the highest ethical standards, ensuring
              that our products positively impact lives while respecting the
              environment and society at large.
            </p>
            {/* <div className="detail_btn_22  mt-5"><Link to={'/companyDetails'}>Details</Link></div> */}
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="img-abt d-sm-flex justify-content-end d-none ms-5">
              <div className="float-area ">
                {/* <img className="about-capsule_an_right floating-img_22" src={capsule_2}/> */}
              </div>
              <LazyLoadImage  src={aboutImg} className="about-img" alt="aboutImg"/>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-md-12">
            <h6 className="text-dark fw-bold">
              What we will do to establish your business ?
            </h6>
            <p className=" text-dark fw-normal">
              A <a href="https://orangebiotech.in/top-pharma-franchise-company-india/">Pharma PCD Franchise Company </a> is a company that offers its
              products and brand name to other distributors or retailers, who
              can market and sell them in their respective territories. Our
              Company usually provides the franchisees with the following
              benefits:
            </p>
            <ul className="mt-2">
              <li className="">
                Exclusive marketing and distribution rights for the products in
                a specific area.
              </li>
              <li>
                {" "}
                Access to a wide range of high quality and affordable
                pharmaceutical products, such as medicines, healthcare products,
                cosmetics, etc.
              </li>
              <li>
                Support and guidance from the company in terms of training,
                promotion, advertising, etc.
              </li>
              <li>
                {" "}
                Low investment and risk, as the franchisees do not have to incur
                the costs of product development, manufacturing, research, etc.
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-12">
            <h6 className="text-dark fw-bold">Challenges to face:</h6>
            <ul>
              <li>
                Obtaining the necessary licenses and permits, such as drug
                license, GST number, FSSAI registration, etc., to operate a
                <a href="https://biostempharma.com/pharma-franchise/">Pharma Franchise </a> Business legally and ethically.
              </li>
              <li>
                Finding a reliable and efficient supply chain for your products,
                as the quality and availability of the products depend on the
                <a href="https://curasiamedilabs.com/pharma-franchise-company/">Pharma Franchise Company </a> and its suppliers.
              </li>
              <li>
                Competing with other Pharma Franchise Businesses and local
                pharmacies in your area, as the Pharma Franchise market is
                highly competitive and saturated.
              </li>
              <li>
                {" "}
                Maintaining customer loyalty and satisfaction, as the customers
                may have different preferences and expectations for the products
                and services they buy.
              </li>
              <li>
                Adapting to the changing market trends and customer demands, as
                the Pharma Franchise industry is dynamic and evolving.
              </li>
            </ul>
          </div>
          <div className="col-12">
            <h6 className="text-dark fw-bold mt-2">Marketplace where demand & supply meet:</h6>
            <p className="text-dark fw-normal">
              Buyers to find the best <a href="https://www.bioversalremedies.com/pharma-franchise-companies/"> pharma franchise companies </a> and third party
              manufacturers for their needs, and helps Sellers to get the
              relevant leads and enquiries for their products and services. How
              it works ?
            </p>
            <ul>
              <li>
                {" "}
                Buyers can browse through the portal and choose from various
                categories of pharma products and services, such as <a href="https://www.bioversalremedies.com/pcd-pharma-franchise/">PCD Pharma Franchise </a>, <a href="https://pharmapcdfranchise.co.in/monopoly-pcd-pharma-franchise/"> Monopoly Pharma Franchise </a>, <a href="https://pharmapcdfranchise.co.in/why-pcd-pharma-companies-need-to-go-for-third-party-manufacturing"> Third Party Manufacturing </a>,
                etc. They can also filter their search by location, product
                range, price, rating, etc.
              </li>
              <li>
                Buyers can view the profiles of the pharma franchise companies
                and third party manufacturers, and check their product catalogs,
                price lists, terms and conditions, feedback, and testimonials.
                They can also contact them directly through the portal or
                request a quote.
              </li>
              <li>
                Sellers can register on the portal and create their profiles,
                where they can showcase their products and services, and provide
                their contact details, company information, certifications, etc.
                They can also upload their product catalogs, price lists, terms
                and conditions, etc.
              </li>
              <li>
                Sellers can receive enquiries and leads from the buyers who are
                interested in their products and services. They can also respond
                to them through the portal or contact them directly. They can
                also track and manage their enquiries and leads through the
                portal.
              </li>
            </ul>
          </div>
          <div className="col-12">
            <h6 className="text-dark fw-bold mt-2">Benefits Of Choosing Us:</h6>
            <ul>
              <li>
                {" "}
                It requires low investment and risk, as the franchisees do not
                have to bear the costs of product development, manufacturing,
                research, etc.
              </li>
              <li>
                It offers exclusive marketing and distribution rights for the
                products in a specific area, which reduces the competition and
                increases the profitability.
              </li>
              <li>
                It provides access to a wide range of high quality and
                affordable pharmaceutical products, covering various therapeutic
                segments and categories.
              </li>
              <li>
                {" "}
                It gives marketing and promotional support from the pharma
                company, such as visual aids, product literature, and
                promotional items, which help in effective sales and branding.
              </li>
              <li>
                It allows the franchisees to operate in a monopoly-based
                business model, which gives them the freedom and flexibility to
                run their business as per their preference and budget.
              </li>
            </ul>
          </div>
          {/* <div className="col-lg-6 col-md-6 col-sm-12">

          </div> */}
        </div>
      </div>
    </section>
  );
};

export default About;
