import React, { useEffect, useState } from "react";
import { Carousel } from "rsuite";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import { img2, img3 } from "../Assets/images/Image";
import { getImgIcon } from "../services/api";

const shuffleArray = (array) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const ImageSlider = () => {
  const [isImage, setImage] = useState([]);

  useEffect(() => {
    getDataApi();
  }, []);

  const getDataApi = async () => {
    const validateResponse = (data) => (Array.isArray(data) ? data : []);
    try {
      const res = await axios.get(getImgIcon);
      const data = validateResponse(res.data);
      const shuffledData = shuffleArray(data);
      setImage(shuffledData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleUrl = (url) => {
    window.open(url);
  };

  return (
    <Carousel className="custom-slider" autoplay>
      {/* First slide */}
      <div className="carousel_item_45">
        <LazyLoadImage
          className="slider_image_main"
          src={img2}
          alt="Slide1"
          loading="lazy"
        />
      </div>

      {/* Second slide */}
      <div className="carousel_item_45">
        <div className="slid-img-logos container">
          <div className="slid-img1 row row-cols-3">
            {isImage.slice(0, 15).map((item, index) => (
              <LazyLoadImage
                key={index}
                src={item.image}
                alt={`Logo ${index}`}
                className="col"
                onClick={() => handleUrl(item.url)}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Third slide */}
      <div className="carousel_item_45">
        <LazyLoadImage className="slider_image_main" src={img3} alt="Slide 3" />
      </div>
    </Carousel>
  );
};

export default ImageSlider;
