import React from "react";
import InputForm from "./InputForm";

const Modal = () => {
  return (
    <>
      <>
        {/* Modal */}
        <div
          className="modal fade"
          id="exampleModals"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content px-3">
              <div className="modal-header">
                <h6 className="modal-title text-center " id="exampleModalLabel">
                  SEND YOUR ENQUIRY
                </h6>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <InputForm />
              </div>
             
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Modal;
