import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import { useDispatch } from "react-redux";
import PharmaCard from "./PharmaCard";
import { SetCompanyName } from "../Redux/Action";
import { Link } from "react-router-dom";
import { getCompanyCatData } from "../services/api";

const PharmaCategory = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [divData, setDivData] = useState([]);
  //console.log(divData,'============================');

  useEffect(() => {
    apiData();
  }, []);
  const apiData = async () => {
    try {
      const res = await axios.get(getCompanyCatData);
      setDivData(res.data.division);
      setData(res.data.company);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleUrl = (item) => {
    window.open(item);
  };
  const handleCompanyName = (item) => {
    //console.log(item, "company name====>");
    dispatch(SetCompanyName(item));
  };
  return (
    <>
      <section id="company">
        <PharmaCard
          data={data}
          // displayedData={displayedData}
          handleUrl={handleUrl}
          handleCompanyName={handleCompanyName}
        />
        <div className="cardparent">
          <div className="container  pb-sm-5">
            <div className="row justify-content-center">
              {divData?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-lg-4  col-md-6 mt-3 mb-3 d-flex justify-content-center"
                  >
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="features-product-content">
                          <Link to={`/divisions-details/${item.title_slug}`}>
                            <div className="main-img">
                              <LazyLoadImage src={item.image} alt="img" />
                            </div>
                            <h6
                              className="title text-center  py-3"
                              style={{ color: "grey" }}
                            >
                              {item.title_slug}
                            </h6>
                          </Link>
                          {/* <p className="card_content_2">
                          {item.description_front.slice(0, 30)}
                          <span>...</span>
                        </p> */}
                          <div className="features-product-bottom">
                            {/* <Link
                            to={item.url}
                            className="st-btn st-style1 st-size-large st-color1"
                          >
                            Profile
                          </Link> */}
                            <Link
                              to={item.url}
                              // to={`/divisions-details/${item.title_slug}`}
                              type="button"
                              className="st-btn st-style1 st-size-large st-color1"
                            >
                              Details
                            </Link>

                            <button
                              onClick={() => handleCompanyName(item.title)}
                              type="button"
                              className="st-btn st-style1 st-size-large st-color1"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModals"
                            >
                              Enquiry
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PharmaCategory;
