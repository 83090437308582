import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import axios from "axios";
import { GetStateData, PostFormData } from "../services/api";
import { BeatLoader } from "react-spinners";

const InputForm = () => {
  const companyName = useSelector((state) => state.reducer.company);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectDist, setSelectDist] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getDataApi();
  }, []);

  const getDataApi = async () => {
    const validateResponse = (data) => (Array.isArray(data) ? data : []);
    try {
      const res = await axios.get(GetStateData);
      const data = validateResponse(res.data);
      setStateList(data);
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };
  const handleStateChange = (select_state) => {
    // console.log(`Selected State : ${select_state}`);
    setSelectedState(select_state);
  };
  // console.log(stateList, "kkkk");
  const handleDist = (SelectDistrict) => {
    setSelectDist(SelectDistrict);
  };
  useEffect(() => {
    districtData();
  }, [selectedState]);

  const districtData = () => {
    try {
      setDistrictList(
        stateList?.filter((item) => item.state_name === selectedState)
      );
    } catch (error) {}
  };
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("mobile_no", data.mobile_no);
      formData.append("details", data.details);
      formData.append("gst_no", data.gst_no || "No");
      formData.append("drug_license", data.drug_license || "No");
      formData.append("state", data.state);
      formData.append("district", data.district);
      formData.append("company_name", companyName);

      const res = await axios.post(PostFormData, formData);
      setIsLoading(false);

      enqueueSnackbar("Data sent successfully!", { variant: "success" });
      reset();
    } catch (error) {
      enqueueSnackbar("Error sending data!", { variant: "error" });
      console.error("Error submitting form:", error);
      setIsLoading(false);
    }
  };

  return (
    <section className="Enquiry1">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="name" className="form-label fw-normal fs-6">
              Name
            </label>
            <input
              type="text"
              className="form-control border border-info py-2"
              placeholder="Name"
              {...register("name", { required: true })}
            />
            {errors.name && (
              <span className="text-danger">Name is required</span>
            )}
          </div>
          <div className="col-md-6">
            <label htmlFor="email" className="form-label fw-normal fs-6">
              Email address
            </label>
            <input
              type="email"
              className="form-control border border-info py-2"
              placeholder="name@gmail.com"
              {...register("email", { required: true })}
            />
            {errors.email && (
              <span className="text-danger">Email is required</span>
            )}
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-md-6">
            <label htmlFor="mobile_no" className="form-label fw-normal fs-6">
              Phone
            </label>
            <input
              type="text"
              className="form-control border border-info py-2"
              placeholder="0123456789"
              {...register("mobile_no", { required: true })}
            />
            {errors.mobile_no && (
              <span className="text-danger">Phone is required</span>
            )}
          </div>
          <div className="col-md-6">
            <label htmlFor="state" className="form-label fw-normal fs-6">
              Select State/UT
            </label>
            <select
              name="state_name"
              className="form-select border border-info"
              {...register("state_name", {
                required: true,
                onChange: (e) => {
                  handleStateChange(e.target.value);
                },
              })}
            >
              <option value="">Select State/UT</option>
              {stateList
                ?.filter(
                  (item, index, self) =>
                    self.findIndex(
                      (el) => el.state_name === item.state_name
                    ) === index
                )
                .map((item, index) => (
                  <option value={item.state_name} key={index}>
                    {item.state_name}
                  </option>
                ))}
            </select>
            {errors.state && (
              <span className="text-danger">State is required</span>
            )}
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-md-6">
            <label htmlFor="district" className="form-label fst-normal fs-6">
              Select District
            </label>
            <select
              name="district"
              className="form-select border border-info"
              onChange
              {...register("district", {
                required: true,
                onChange: (e) => {
                  handleDist(e.target.value);
                },
              })}
            >
              <option value="">Select District</option>
              {districtList.map((item, index) => {
                // console.log(item, "dii"); // Add this console.log statement
                return (
                  <option value={item.district_name} key={index}>
                    {item.district_name}
                  </option>
                );
              })}
            </select>
            {errors.district && (
              <span className="text-danger">District is required</span>
            )}
          </div>
          <label htmlFor="details" className="mt-2 fst-normal fs-6">
            Details
          </label>
          <div className="py-2">
            <textarea
              className="form-control p-2 border border-info"
              placeholder="Leave a comment here"
              style={{ height: 100 }}
              {...register("details", { required: true })}
            />
            {errors.details && (
              <span className="text-danger">Details are required</span>
            )}
          </div>
          <div className="mt-2 py-2">
            <div className="mt-2 py-2">
              <label className="fst-normal fs-6">Do you have GST No.</label>
              <input
                type="radio"
                className="mx-1"
                name="gst_no"
                value="Yes"
                {...register("gst_no")}
              />{" "}
              <span className="fst-normal fs-6">Yes</span>
              <input
                type="radio"
                className="mx-1"
                name="gst_no"
                value="No"
                {...register("gst_no")}
              />{" "}
              <span className="fst-normal fs-6">No</span>
            </div>
            <div className="py-2">
              <label className="fst-normal fs-6">
                Do you have Drug License.
              </label>
              <input
                type="radio"
                name="drug_license"
                value="Yes"
                className="mx-1"
                {...register("drug_license")}
              />{" "}
              <span className="fst-normal fs-6">Yes</span>
              <input
                type="radio"
                name="drug_license"
                value="No"
                className="mx-1"
                {...register("drug_license")}
              />{" "}
              <span className="fst-normal fs-6">No</span>
            </div>
            <div className="my-3">
              <button
                className="btn btn-primary btn-lg border-0 post-align"
                type="submit"
              >
                {isLoading ? <BeatLoader /> : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default InputForm;
