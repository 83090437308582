import axios from "axios";
import React, { useEffect, useState } from "react";
import { HiOutlineLink } from "react-icons/hi";
import { parseISO, format } from "date-fns";
import { Link } from "react-router-dom";
import LoaderDiv from "../components/LoaderDiv";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { BlogApi } from "../services/api";
import Seo from "../Layouts/Seo";

const BlogPages = ({ position }) => {
  const [data, setData] = useState([]);
  //console.log(data, "is data for testing ");

  const [loading, setLoading] = useState(false);
  //console.log(loading, "======");

  useEffect(() => {
    window.scrollTo(0, position);
  }, [position]);

  useEffect(() => {
    setLoading(true);
    apiData();
  }, []);

  const apiData = async () => {
    const validateResponse = (data) => (Array.isArray(data) ? data : []);
    setLoading(true);
    try {
      const res = await axios.get(BlogApi);
      const validateData = validateResponse(res.data);
      setData(validateData);
      setLoading(validateData.length === 0);
    } catch (error) {
      setLoading(true);
    }
  };
  return (
    <>
      <Seo
        title="Blog Page | Top PCD Pharma Company - India"
        description="Pharma PCD Franchise is the Top PCD Pharma Company in India.Get 60000+ Products in Pharma PCD Franchise from the list of the best 10 PCD Pharma franchise companies in India."
        type="webapp"
        name="Pharma Company"
        pageUrl={`https://pharmapcdfranchise.co.in/blog/`}
      />
      <div className="container ">
        <div className="st-section-heading st-style1">
          <h2 className="st-section-heading-title blog-padding">
            Latest Blogs
          </h2>
          <div
            className="text-center fw-bold para-padding"
            style={{ color: "#499afa", fontWeight: "600" }}
          >
            Sustainability in Pharmaceuticals: A New Era of Responsibility
          </div>
        </div>
        <div className="st-height-b40 st-height-lg-b40" />
      </div>
      <div className="container">
        <div className="row">
          {loading ? (
            <div className="d-flex justify-content-center">
              <LoaderDiv />
            </div>
          ) : (
            data.map((item) => {
              const dateObject = parseISO(item.CreatedAt);
              const formattedDate = format(dateObject, "MMM dd, yyyy");
              return (
                <div className="col-lg-4 col-md-6 mt-4" key={item.id}>
                  <div className="st-post st-style3">
                    <Link
                      to={`/${item.slug}/`}
                      className="st-post-thumb st-link-hover-wrap st-zoom"
                    >
                      <LazyLoadImage
                        className="med st-zoom-in"
                        src={item.image}
                        alt={item.image}
                      />
                      <span className="st-link-hover">
                        <i className="fas fa-link">
                          <HiOutlineLink />
                        </i>
                      </span>
                    </Link>
                    <div className="st-post-info">
                      <h5 className="st-post-titlest-post-titlest-post-title text-center mt-2 one-line-text px-2">
                        <Link to={`/${item.slug}/`} style={{ color: "grey" }}>
                          {" "}
                          {item.title.charAt(0).toUpperCase() +
                            item.title.slice(1)}
                        </Link>
                      </h5>
                      <div className="st-post-meta d-flex justify-content-between">
                        <span className="st-post-date fw-bolder">
                          {formattedDate}
                        </span>
                      </div>

                      <div
                        className="st-post-text two-line-text"
                        dangerouslySetInnerHTML={{
                          __html: item?.description_front,
                        }}
                      />
                    </div>
                    <div className="st-post-footer ">
                      <Link
                        to={`/${item.slug}/`}
                        className="btn-refremerj"
                        style={{ color: "grey" }}
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                  <div className="st-height-b0 st-height-lg-b30" />
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default BlogPages;
